const ServerURL = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  WS_BASE_URL: process.env.REACT_APP_API_WS_BASE_URL,
};

// const ServerURL = {
//   BASE_URL: "http://127.0.0.1:8000",
//   WS_BASE_URL: "ws://127.0.0.1:8000",
// };

export default ServerURL;
